import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Jordan",
  "subtitle": "September 2017",
  "category": "Middle East"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Local SIM - I picked one up at the airport which roamed on `}<a parentName="li" {...{
          "href": "https://www.jo.zain.com/english/"
        }}>{`Zain`}</a></li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Baklava"
        }}>{`Baklava`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Falafel"
        }}>{`Falafel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kanafeh"
        }}>{`Kunafa`}</a>{` - Habibah Sweets in Amman is the best!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Mansaf"
        }}>{`Mansaf`}</a>{` - Lamb cooked in fermented dry yogurt with rice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Maqluba"
        }}>{`Maqluba`}</a>{` - Known as the "upside down dish"`}</li>
      <li parentName="ul">{`Bedouin tea - Usually a black tea with sage, wild mint, cardamom and other herbs`}</li>
      <li parentName="ul">{`Pistachio ice cream`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.sujab.com/index.php"
            }}>{`Eisberg`}</a>{` is a Jordanian brand sold at the local supermarket`}</li>
          <li parentName="ul">{`I'm not sure why but it's everywhere`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Shisha - Lots of flavors to choose from`}</li>
      <li parentName="ul">{`Zarb Bedouin barbecue - that requires a hole to be dug in the ground`}</li>
    </ul>
    <h2 {...{
      "id": "amman"
    }}>{`Amman`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aUfPGczjWtkGGcLT6"
        }}>{`Habibah Sweets`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The alley is the original location and the line is long but moves fast`}</li>
          <li parentName="ul">{`Khishnah (made from long and thin noodle threads) is crispy`}</li>
          <li parentName="ul">{`Na'ama (made from semolina) is soft`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/oRAvnjaXbBE5eD4R9"
        }}>{`Abuzaghla`}</a>{` - The Mansaf here comes in a large portion and the staff were very nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A57pmB4xPrWeN1p87"
        }}>{`Hashem Restaurant`}</a>{` - Serves lots of Jordanian street food; The falafel is a must!`}</li>
    </ul>
    <h2 {...{
      "id": "jerash"
    }}>{`Jerash`}</h2>
    <ul>
      <li parentName="ul">{`I read `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jerash"
        }}>{`the history`}</a>{` on my phone`}</li>
      <li parentName="ul">{`Lack of shade so bring sunscreen and wear a hat as it can get really hot midday`}</li>
    </ul>
    <h2 {...{
      "id": "mukawir-and-dead-sea"
    }}>{`Mukawir and Dead sea`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sBRoECxDqjksDGGB8"
        }}>{`Fortress of Machaerus`}</a>{` - Did a ~4 hour hike towards the dead sea. Great views and was practically empty.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dssh.jo/"
        }}>{`Dead Sea Spa Hotel`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Has a private beach and mud was free`}</li>
          <li parentName="ul">{`They have a buffet dinner and the bread pudding was awesome!`}</li>
          <li parentName="ul">{`There were singers and a dancer rotating throughout the night at the outdoor tables. Shisha was extra.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "dead-sea"
    }}>{`Dead sea`}</h3>
    <ul>
      <li parentName="ul">{`Keep your head above the water and let the rest of your body just float. It’s a weird sensation`}</li>
      <li parentName="ul">{`Avoid the water if you have open wounds though cause it’ll sting`}</li>
      <li parentName="ul">{`Avoid getting any on your lips cause it’s very salty`}</li>
    </ul>
    <h2 {...{
      "id": "petra"
    }}>{`Petra`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://whc.unesco.org/en/list/326/"
        }}>{`UNESCO`}</a></li>
      <li parentName="ul">{`Be warned that there's lots of horse, camel, and donkey poo so watch your step! It can be quite smelly... There's also little shade so dress appropriately; strongly advise to wear a headscarf or buy a hat`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZiQ1M6HcjywMY55Q6"
        }}>{`Little Petra`}</a>{` to the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/isVpXZuwMRqeTpC1A"
        }}>{`Monastery`}</a>{` hike`}
        <ul parentName="li">
          <li parentName="ul">{`This was amazing! It was around 4km so bring water and lunch`}</li>
          <li parentName="ul">{`There's a tea shop at the top, which is about halfway`}</li>
          <li parentName="ul">{`Not very crowded since this trial is longer than others`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ost8UKszqwVRYiBw9"
        }}>{`High Place of Sacrifice Trail`}</a>{` - Steep half hour climb but nice view at the end`}</li>
      <li parentName="ul">{`Camel rides - I don't do these but I was told to not pay over 10 JOD (at the time)`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mzT3BKHAMLrdmNCc8"
        }}>{`The Cave Bar`}</a>{` - Cute interior`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iZxnvYfwscxobK747"
        }}>{`Red Cave`}</a>{` - Decent meal but nothing to rave about. Don’t let the guy tell you it’s cash only when the door says “Visa accepted here”`}</li>
    </ul>
    <h2 {...{
      "id": "wadi-rum"
    }}>{`Wadi Rum`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.wadirumbedouincamp.com/"
        }}>{`Wadi Rum Bedouin Camp`}</a>{` - I was on a tour that did a 2 night camp`}
        <ul parentName="li">
          <li parentName="ul">{`Try to conserve the water as it’s driven in by cars`}</li>
          <li parentName="ul">{`The bathroom was very clean but the shower is cold if you decide to take one`}</li>
          <li parentName="ul">{`Stargazing at night was amazing since there’s no light pollution`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/GsP2HtmUYzw6ijFC8"
        }}>{`Wadi Rum Visitor Center`}</a>{` - There's practically no reception after this so do any internet related things beforehand`}</li>
    </ul>
    <h2 {...{
      "id": "madaba"
    }}>{`Madaba`}</h2>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VZrAsUHL5QiPNupP8"
        }}>{`Bawabit Madaba`}</a>{` - Food was good and had a nice view`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XBab6aDJYtUZHPk17"
        }}>{`Frankfurter Supermarket & Cafe`}</a>{` - It's next door to Bawabit and I bought the best rolled baklava here!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TfJLHD9RBXdH3DML8"
        }}>{`Abu Jaber Sweet`}</a>{` - Was craving kunafeh but it was so-so`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hfkSXD9hnNVk45Xw8"
        }}>{`St George’s Greek Orthodox Church`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Madaba_Map"
            }}>{`Madaba mosaic map`}</a>{` is worth the visit`}</li>
          <li parentName="ul">{`I hired a taxi here and only spent about an hour maybe?`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/UEreFrvgiyuLYsfu7"
        }}>{`Madaba Archaeological Park`}</a>{` - The mosaics were amazing!`}</li>
      <li parentName="ul">{`Mosaic Handicraft Centers - There were quite a few and they are willing to ship internationally`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      